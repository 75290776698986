import React,{Component} from 'react';
import classes from './EditCoinForm.module.css';
import {Form, Button, Modal, Image, Row} from 'react-bootstrap';
import Utils from '../Utils.js'
import AdminServices from "../Services/AdminServices";
import LinkForm from "../Components/LinkForm";
import ContractForm from "../Components/ContractForm";
import CommentForm from "../Components/CommentForm";
import Col from "react-bootstrap/Col";
import * as firebase from "firebase";
import * as Icon from 'react-bootstrap-icons';


class EditCoinForm extends Component{

  state={
    viewCoinComments: true,
    coin: null,
    tempVotesCount: 0,
    marketCap: 0,
    symbol: "",
    other: "",
    comments: [],
    votesCount: 0,
    isPublished: false,
    description: "",
    launchDate: 0,
    contracts: [],
    isPromoted: false,
    price: 0,
    isPromoted4thATB: false,
    today: false,
    isVotingDisabled: false,
    name: "",
    logo: "",
    links: [],
    id: "",
    hunter: "",
    isPromoted4thToday: false,
    isReviewed: false,
    lastVoteDate: 0,
    show: false,
    isAuthorized: false
  }

  adminServices = new AdminServices()

  componentDidMount(){

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
          if(Utils.whiteListAdmin.includes(user.uid)) {
              this.setState({ isAuthorized: true });
          }
          else {
              window.location.href='/'
          }
      }
      else {
          window.location.href='/'
      }
    })

    this.adminServices.getCoinById(this.props.id)
        .then(response => {
          let coin = response.res[0]
          this.setState({
            tempVotesCount: coin.tempVotesCount,
            marketCap: coin.marketCap,
            symbol: coin.symbol,
            other: coin.other,
            comments: coin.comments.reverse(),
            votesCount: coin.votesCount,
            isPublished: coin.isPublished,
            description: coin.description,
            launchDate: Utils.formatDateTime(coin.launchDate),
            contracts: coin.contracts,
            isPromoted: coin.isPromoted,
            price: coin.price,
            isPromoted4thATB: coin.isPromoted4thATB,
            today: coin.today,
            isVotingDisabled: coin.isVotingDisabled,
            name: coin.name,
            logo: coin.logo,
            links: coin.links,
            id: coin.id,
            hunter: coin.hunter,
            isPromoted4thToday: coin.isPromoted4thToday,
            titleCoin: coin.name,
            isReviewed: coin.isReviewed,
            lastVoteDate: Utils.formatDateTime(coin.lastVoteDate)
          });
        })
        .catch(err => {
          if(err.toString().includes("403")){
            alert("Session timed out")
            window.location.href='/admin'
          }
        })
  }

  addLinkForm = (e) => {
    this.setState((prevState) => ({
      links: [...prevState.links, {name:"", value:""}]
    }))
  }

  addContractForm = (e) => {
    this.setState((prevState) => ({
      contracts: [...prevState.contracts, {name:"", value:""}]
    }))
  }

  onFormControlChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onFormCheckChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  handleClose = () => {
    this.setState({show:false});
  }

  handleShow = () => {
    this.setState({show:true});
  }

  handleViewCoinComments = () => {
    let viewCoinComments=this.state.viewCoinComments
    this.setState({viewCoinComments: !viewCoinComments})
  }

  deleteCoin = (e) =>  {
    this.adminServices.deleteCoin(this.state.id)
        .then(response => {
          this.handleClose()
          window.location.href='/admin/dashboard'
        })
        .catch(err => {
          if(err.toString().includes("403")){
            alert("Session timed out")
            window.location.href='/admin'
          }
          else{
            this.handleClose()
          }
        })

  }

  updateCoin = (e) =>  {

    let links =[]
    this.state.links.map((item,i)=>{
      if(item.name && item.value){
        links.push(item)
      }
    })

    let contracts =[]
    this.state.contracts.map((item,i)=>{
      if(item.name && item.value){
        contracts.push(item)
      }
    })

    const coin = {
      tempVotesCount: this.state.tempVotesCount,
      marketCap: this.state.marketCap,
      symbol: this.state.symbol,
      other: this.state.other,
      comments: this.state.comments,
      votesCount: this.state.votesCount,
      isPublished: this.state.isPublished,
      description: this.state.description,
      launchDate: new Date(this.state.launchDate).getTime(),
      contracts: contracts,
      isPromoted: this.state.isPromoted,
      price: this.state.price,
      isPromoted4thATB: this.state.isPromoted4thATB,
      today: this.state.today,
      isVotingDisabled: this.state.isVotingDisabled,
      name: this.state.name,
      logo: this.state.logo,
      links: links,
      id: this.state.id,
      hunter: this.state.hunter,
      isPromoted4thToday: this.state.isPromoted4thToday,
      isReviewed: this.state.isReviewed,
      lastVoteDate: new Date(this.state.lastVoteDate).getTime()
    };

    this.adminServices.updateCoin(coin)
        .then(response => {
          window.location.href='/admin/dashboard'
        })
        .catch(err => {
          if(err.toString().includes("403")){
            alert("Session timed out")
            window.location.href='/admin'
          }
        })
  }

  renderContent() {
    if(this.state.isAuthorized) {
        return(
          <div>
            <div className={classes.BackButton} onClick={() => window.location='/admin/dashboard'}>
              <Icon.ArrowLeftShort style={{width: "30px", height: "30px", paddingBottom: "3px"}} />
              <span>Back to list</span>
            </div>
            <h1>Coin : {this.state.titleCoin}</h1>
          <h5>ID:{this.state.id}</h5>

          <div style={{margin: "30px 0"}}>
            <Button disabled={this.state.viewCoinComments} variant={!this.state.viewCoinComments ? "light" : "dark"} onClick={this.handleViewCoinComments}>
              Coin
            </Button>
            <Button disabled={!this.state.viewCoinComments} variant={this.state.viewCoinComments ? "light" : "dark"} onClick={this.handleViewCoinComments}>
              Comments
            </Button>
          </div>

            <Form style={{display: !this.state.viewCoinComments ? "none" : "block"}}>
              {
                this.state.contracts.map((item, i) => {
                  if(item.name==="Binance Smart Chain"){
                    return (
                        <div key={"contract-button-"+i} style={{marginBottom: "30px"}}>
                          <a href={"https://poocoin.app/tokens/"+item.value} target="_blank">
                            <Button variant="success" style={{marginRight: "20px"}}>
                              Poocoin
                            </Button>
                          </a>
                          <a href={"https://bscscan.com/token/"+item.value} target="_blank">
                            <Button variant="success" >
                              BscScan
                            </Button>
                          </a>
                        </div>
                    )
                  }
                })}
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control required maxLength="50" value={this.state.name} name={"name"} onChange={this.onFormControlChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Symbol</Form.Label>
                <Form.Control required maxLength="50" value={this.state.symbol} name={"symbol"} onChange={this.onFormControlChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Hunter</Form.Label>
                <Form.Control required maxLength="50" value={this.state.hunter} name={"hunter"} onChange={this.onFormControlChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={3} maxLength="1000" value={this.state.description} name={"description"} onChange={this.onFormControlChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Other</Form.Label>
                <Form.Control as="textarea" rows={3} maxLength="1000" value={this.state.other} name={"other"} onChange={this.onFormControlChange}/>
              </Form.Group>
              <div>
                <Form.Label>Links</Form.Label>
                <Button variant={"success"} onClick={this.addLinkForm} style={{float:"right"}}>+</Button>
                <LinkForm links={this.state.links}/>
              </div>
              <div>
                <Form.Label>Contracts</Form.Label>
                <Button variant={"success"} onClick={this.addContractForm} style={{float:"right"}}>+</Button>
                <ContractForm contracts={this.state.contracts}/>
              </div>
              <Form.Group>
                <Form.Label>Logo link</Form.Label>
                <Form.Control maxLength="300" value={this.state.logo} name={"logo"} onChange={this.onFormControlChange}/>
                <div style={{textAlign: "center"}}>
                  <img className={classes.image} src={this.state.logo } alt="Logo" />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>Actual price</Form.Label>
                <Form.Control required type="number" min="0" step=".00000000000000001" value={this.state.price} name={"price"} onChange={this.onFormControlChange}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Actual market cap</Form.Label>
                <Form.Control required type="number" min="0" value={this.state.marketCap} name={"marketCap"} onChange={this.onFormControlChange}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Launch date</Form.Label>
                <Form.Control type="datetime-local" required value={this.state.launchDate} name={"launchDate"} onChange={this.onFormControlChange}/>
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group >
                    <Form.Label>Today</Form.Label>
                    <Form.Switch type="switch" id={"today"} checked={this.state.today} name={"today"} onChange={this.onFormCheckChange}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Is Published</Form.Label>
                    <Form.Switch type="switch" id={"isPublished"} checked={this.state.isPublished} name={"isPublished"} onChange={this.onFormCheckChange}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Is Reviewed</Form.Label>
                    <Form.Switch type="switch" id={"isReviewed"} checked={this.state.isReviewed} name={"isReviewed"} onChange={this.onFormCheckChange}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Is Voting Disabled</Form.Label>
                    <Form.Switch type="switch" id={"isVotingDisabled"} checked={this.state.isVotingDisabled} name={"isVotingDisabled"} onChange={this.onFormCheckChange}/>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Is Promoted</Form.Label>
                    <Form.Switch type="switch" id={"isPromoted"} checked={this.state.isPromoted} name={"isPromoted"} onChange={this.onFormCheckChange}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Is Promoted 4th ATB</Form.Label>
                    <Form.Switch type="switch" id={"isPromoted4thATB"} checked={this.state.isPromoted4thATB} name={"isPromoted4thATB"} onChange={this.onFormCheckChange}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Is Promoted 4th Today</Form.Label>
                    <Form.Switch type="switch" id={"isPromoted4thToday"} checked={this.state.isPromoted4thToday} name={"isPromoted4thToday"} onChange={this.onFormCheckChange}/>
                  </Form.Group>
                </Col>
                <Col>
                </Col>
              </Row>

              <Form.Group>
                <Form.Label>Votes Count</Form.Label>
                <Form.Control required type="number" step="1" value={this.state.votesCount} name={"votesCount"} onChange={this.onFormControlChange}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Temp Votes Count</Form.Label>
                <Form.Control required type="number" step="1" value={this.state.tempVotesCount} name={"tempVotesCount"} onChange={this.onFormControlChange}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Last Vote Date</Form.Label>
                <Form.Control type="datetime-local" required value={this.state.lastVoteDate} name={"lastVoteDate"} onChange={this.onFormControlChange}/>
              </Form.Group>
              <Button variant="danger" style={{marginRight: "20px"}} onClick={this.handleShow}>
                Delete
              </Button>
              <Button variant="primary" onClick={this.updateCoin}>
                Confirm
              </Button>
            </Form>

            <Form style={{display: this.state.viewCoinComments ? "none" : "block"}}>
              <CommentForm comments={this.state.comments} id={this.state.id}/>
            </Form>
            </div>
        )
    }
  }

  render() {
    return(
      <div>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
              <Modal.Title>Coin removal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <React.Fragment>
              <p>Confirm permanent deletion</p>
          </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                  Cancel
              </Button>
              <Button variant="danger" onClick={this.deleteCoin}>
                  Delete
              </Button>
          </Modal.Footer>
        </Modal>

        <div className={classes.contentwrapper}>
          {this.renderContent()}
        </div>
          
      </div>
    );
  }

}
export default EditCoinForm;