import React, {Component} from "react"
import {Button, Form, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
class ContractForm extends Component{


    onFormControlChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        let type = e.target.name.split("-")[0]
        let index = e.target.name.split("-")[1]
        if(type==="name"){
            this.props.contracts[index].name=e.target.value
        }
        else{
            this.props.contracts[index].value=e.target.value
        }
    };

    render(){
        return (
            this.props.contracts.map((val,i)=>{
                return (
                    <div key={"contract-"+i}>
                        <Form.Group>
                            <Row style={{margin:"10px 0"}}>
                                <Col xs={5} style={{padding:"0"}}>
                                    <Form.Control
                                        type={"text"}
                                        name={"name-"+i}
                                        value={this.props.contracts[i].name}
                                        onChange={this.onFormControlChange}
                                        placeholder={"Contract name"}
                                    />
                                </Col>
                                <Col xs={7} style={{padding:"0"}}>
                                    <Form.Control
                                        type={"text"}
                                        name={"value-"+i}
                                        value={this.props.contracts[i].value}
                                        onChange={this.onFormControlChange}
                                        maxLength="100"
                                        placeholder={"Contract value"}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </div>
                )
            })
        )
    }
}
export default ContractForm;