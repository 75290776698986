import axios from "axios";
import * as firebase from "firebase";

export default class AdminServices {

    baseUrl = "https://api.cnhnt.cc/"

    signIn(user,pwd) {
        return new Promise((resolve, reject) => {
            firebase.auth().currentUser.getIdToken().then(idToken => {
                const url = this.baseUrl + "user/login?token=" + idToken
                const data = {
                    username: user,
                    password: pwd
                };
                axios.post(url, data).then((response) => resolve(response.data)).catch((error) => reject(error))
            })
        })
    }

    signOut() {
        localStorage.removeItem("token")
        window.location.href='/admin'
    }

    getAllCoins(){
        return new Promise((resolve, reject) => {
            const url = this.baseUrl + "admin/getAllCoins"
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            };
            axios.get(url, config).then((response) => resolve(response.data)).catch((error) => reject(error))
        })
    }

    getCoinById(id){
        return new Promise((resolve, reject) => {
            const url = this.baseUrl + "admin/getCoinById/" + id
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.get(url, config).then((response) => resolve(response.data)).catch((error) => reject(error))
        })
    }

    updateCoin(coin){
        return new Promise((resolve, reject) => {
            const url = this.baseUrl + "admin/updateCoin"
            const data = coin
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.put(url, data, config).then((response) => resolve(response.data)).catch((error) => reject(error))
        })
    }

    rotateCoins(){
        return new Promise((resolve, reject) => {
            const url = this.baseUrl + "admin/rotateCoins"
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.get(url, config).then((response) => resolve(response.data)).catch((error) => reject(error))
        })
    }

    deleteCoin(id){
        return new Promise((resolve, reject) => {
            const url = this.baseUrl + "admin/deleteCoin?coinId=" + id
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.delete(url, config).then((response) => resolve(response.data)).catch((error) => reject(error))
        })
    }

    deleteComment(coinId,commentId){
        return new Promise((resolve, reject) => {
            const url = this.baseUrl + "admin/deleteComment?coinId=" + coinId + "&commentId=" + commentId
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.delete(url, config).then((response) => resolve(response.data)).catch((error) => reject(error))
        })
    }

    updateComment(coinId,comment){
        return new Promise((resolve, reject) => {
            const url = this.baseUrl + "admin/updateComment?coinId=" + coinId
            const data = comment
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.post(url, data, config).then((response) => resolve(response.data)).catch((error) => reject(error))
        })
    }

    exportDatabase() {
        return new Promise((resolve, reject) => {
            const url = this.baseUrl + "admin/doExport"
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            };
            axios.get(url, config).then((response) => resolve(response.data.res)).catch((error) => reject(error))
        })
    }

}