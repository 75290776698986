import React,{Component} from 'react';
import classes from './Landing.module.css';
import firebase from 'firebase';
import CoinServices from '../Services/CoinServices.js'
import Utils from '../Utils.js'
import noLogo from '../Resources/Images/noLogo.png';
import Button from 'react-bootstrap/Button';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Badge from 'react-bootstrap/Badge';
import * as Icon from 'react-bootstrap-icons';
import { Spinner, Modal, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import HCaptcha from '@hcaptcha/react-hcaptcha';


const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      var user = authResult.user;
      var credential = authResult.credential;
      var isNewUser = authResult.additionalUserInfo.isNewUser;
      var providerId = authResult.additionalUserInfo.providerId;
      var operationType = authResult.operationType;
      // Do something with the returned AuthResult.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true;
    },
    signInFailure: function(error) {
      // Some unrecoverable error occurred during sign-in.
      // Return a promise when error handling is completed and FirebaseUI
      // will reset, clearing any UI. This commonly occurs for error code
      // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
      // occurs. Check below for more details on this.
      // return handleUIError(error);
    },

  },
  queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
  signInFlow:'popup',
  signInSuccessUrl: '',//Specifying sign in success url can cause double redirect since we are also managing redirect in react-router with local state.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
  // Other config options...
}



Array.prototype.insert = function ( index, item ) {
  this.splice( index, 0, item );
};

class Landing extends Component{
   

  state={
    coinsList: [],
    promotedCoinsList: [],
    userCoinsList:[],
    coinsTodayList:[],
    user:{},
    key:"today",
    show: false,
    showVoteBottingModal: false,
    loadingAll: true,
    loadingShowAllToday: false,
    loadingShowMore: false,
    allTodaysCoinsDisplayed: false,
    loadingAllTime: false,
    votingCoin: null,
    votedCoins: [],
    coin4thplace:{},
    showCaptcha: false,
    searchField: "",
    isSearching: false,
    searchResults: [],
    isAllLoaded: false,
    loadingSearch: false,
    noSearchResults: false,
    allTimeCoinsDisplayed: []
  }

  coinServices = new CoinServices();

  componentWillMount(){

    const voteCache = JSON.parse(localStorage.getItem("votedCoins"));
    this.setState({votedCoins: voteCache ? voteCache : []});

    this.coinServices.getPromotedCoins().then(res => {
      this.setState({promotedCoinsList: res});
    });

    this.coinServices.get4thPromotedCoins().then(res=>{
      if(res) this.setState({coin4thplace: res[0]});
      this.coinServices.getTodaysCoinsApproved(false).then(res => {
        this.setState({coinsTodayList: res});
        this.setState({loadingAll: false});
      });
    });

    var unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if(user.providerData[0]){
          this.setState({user: user});
          this.setState({loggedin:true});

          if(this.state.user.email){
            this.coinServices.getAllCoinsByEmail(this.state.user.email).then(res => {
              this.setState({userCoinsList: res})
            });
          }
          unsubscribe();
        }
      } else {
        // No user is signed in.
        this.setState({loggedin:false});
      }
     });

  }

  render(){
    return this.renderContent();
  }

  renderUserDiv() {
    if(this.state.loggedin && this.state.userCoinsList && this.state.userCoinsList.length > 0) {
      return(
        <div>

          <div className={classes.table}>
            {(this.state.userCoinsList.map(coin => (
                    this.renderUserCoinRow(coin)
                    )))}
          </div>
        </div>
      
      )
    }
  }

  renderPromotedDiv() {
    if(this.state.promotedCoinsList && this.state.promotedCoinsList.length > 0) {
      return(
        <div>
          <div className={classes.TopTitleDiv}>
            <div className={classes.TopTitle}>Promoted coins</div>
          </div>

          <div className={classes.tableTrending}>
            {(this.state.promotedCoinsList.map(coin => (
                this.renderCoinRow(coin)
              )))}
          </div>
        </div>
      )
    }
  }

  renderLoadingSearch() {
    if(this.state.loadingSearch) {
      return(
        <Spinner animation="border" variant="success" />
      )
    }
  }

  searchBar() {
    if(this.state.isSearching){
      return(
        <InputGroup style={{margin: "5px"}}>
          <InputGroup.Prepend>
            <Button variant="success" style={{height: "30px", padding: "5px", fontSize: "0.8rem"}} onClick={() => this.search()}>
              <Icon.Search/> Search
            </Button>
          </InputGroup.Prepend>
          <FormControl
            style={{height: "30px", padding: "5px", fontSize: "0.8rem", marginRight: "10px"}}
            autoFocus
            onChange={this.onSearchChange}
          />
        </InputGroup>
      )
    }
    else {
      return(
        <div>
          <Button variant="success" style={{height: "30px", padding: "5px", fontSize: "0.8rem", float: "left", marginLeft: "5px"}} onClick={() => this.search()}>
            <Icon.Search/> Search
          </Button>
          {this.renderLoadingSearch()}
        </div>
      )
    }
  }

  search() {
    if(this.state.isSearching) {
      this.setState({isSearching: false, searchResults: [], noSearchResults: false});
    }
    else {
      if(!this.state.isAllLoaded) {
        this.setState({loadingSearch: true});
        this.coinServices.getAllCoinsApproved(true).then(res => {
          this.setState({loadingSearch: false, coinsList: res, isSearching: true, isAllLoaded: true});
          if(this.state.allTimeCoinsDisplayed.length == 0) {
            this.setState({allTimeCoinsDisplayed: res.slice(0, 10)})
          }
        });
      }
      else {
        this.setState({isSearching: true});
      }
    }
  }

  onSearchChange = (e) =>{
    let searchedName = e.target.value.trim();
    let filtered = [];
    let count = 0;
    var BreakException = {};

    if(searchedName != "") {
      try {
        this.state.coinsList.forEach(coin => {
            if(coin.name.toLowerCase().includes(searchedName.toLowerCase())
            || coin.symbol.toLowerCase().includes(searchedName.toLowerCase())){
                filtered.push(coin);
                count++;
            }
            if(count == 10) {
              throw BreakException;
            }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
      if(filtered.length == 0) {
        this.setState({noSearchResults: true});
      }
      else{
        this.setState({noSearchResults: false});
      }
    }
    this.setState({ searchResults: filtered });
}

  handleCloseCaptcha = () => {
    this.setState({showCaptcha: false});
  }

  handleShowCaptcha = () => {
    this.setState({showCaptcha: true});
  }

  voteFunction(e, coin, captchaCheck, voteToken){
    if(coin.isVotingDisabled == 1){
      this.handleShowVoteBottingModal();
    }
    else if(captchaCheck) {
      this.setState({votingCoin: coin});
      this.handleShowCaptcha();
    }
    else {
      const timeDif = new Date().getTime() - new Date(Number(coin.lastVoteDate)).getTime();
      
      if(coin.tempVotesCount < 30 || timeDif >= 60000 || timeDif < 0) {

        this.coinServices.vote(coin, voteToken).then(res => {
          // Si erreur, retire le vote du front et du cache
          if(res.data.code != 200) {
            this.updateRow(coin, false);
            let votedCoins = [...this.state.votedCoins];
            votedCoins = votedCoins.filter(e => e != coin.id);
            this.setState({votedCoins: votedCoins});
            localStorage.setItem("votedCoins", JSON.stringify(votedCoins));
            if(res.data.code == 403) {
              this.handleShow();
            }
          }
        });

        let votedCoins = [...this.state.votedCoins];
        votedCoins.push(coin.id);
        this.setState({votedCoins: votedCoins});
        localStorage.setItem("votedCoins", JSON.stringify(votedCoins));
        this.updateRow(coin, true);
      }
      else {
        this.handleShow();
      }
    }
    if(e) {
      e.stopPropagation();
    }
  }

  unVoteFunction(e, coin){

    this.coinServices.unVote(coin).then(res => {
      // Si erreur, remet le vote en front et en cache
      if(res.data.code != 200) {
        this.updateRow(coin, true);
        let votedCoins = [...this.state.votedCoins];
        votedCoins.push(coin.id);
        this.setState({votedCoins: votedCoins});
        localStorage.setItem("votedCoins", JSON.stringify(votedCoins));
      }
    });

    let votedCoins = [...this.state.votedCoins];
    votedCoins = votedCoins.filter(e => e != coin.id);
    this.setState({votedCoins: votedCoins});
    localStorage.setItem("votedCoins", JSON.stringify(votedCoins));
    this.updateRow(coin, false);
    e.stopPropagation();
  }

  renderVoteButton(coin){
    var nbVotes = coin.votesCount;
    if(coin.hasVoted || this.state.votedCoins.includes(coin.id)){
      return (
        <Col sm={2} xs={3} className={"px-0 align-self-center " + classes.ColFontSize} onClick={(e)=>{
          if(e.ctrlKey) {
            window.open('/coin/'+coin.id, '_blank');
          }
          else {
            window.location='/coin/'+coin.id;
          }
        }}>
          <Button variant="success" className={classes.VoteButton} onClick= {(e) => {this.unVoteFunction(e, coin)}}>
            <div style={{display: "flex", justifyContent: "center"}}>
            <Icon.ArrowUpShort  className={classes.VoteIcon} />
              <div className={classes.VoteText} >
                {nbVotes}
              </div>
            </div>
          </Button>
        </Col>
      );
    }
    else{
      return (
        <Col sm={2} xs={3} className={"px-0 align-self-center " + classes.ColFontSize} onClick={(e)=>{
          if(e.ctrlKey) {
            window.open('/coin/'+coin.id, '_blank');
          }
          else {
            window.location='/coin/'+coin.id;
          }
        }}>
          <Button variant="outline-success" className={classes.VoteButton} onClick= {(e) => {this.voteFunction(e, coin, true)}}>
            <div style={{display: "flex", justifyContent: "space-evenly"}}>
            <Icon.ArrowUpShort className={classes.VoteIcon} />
              <div className={classes.VoteText}>
                {nbVotes}
              </div>
            </div>
          </Button>
        </Col>
      );
    }
  }


  renderUserCoinRow(coin) {
    return(
      <div key={coin.id} className={classes.RowContain}>
        <a href={'/coin/'+coin.id} className={classes.RowLink}>
          <Row style={{height: "60px"}} className="mx-0 w-100">
            <Col className={"px-0 align-self-center " + classes.ColFontSize}>
              <Row style={{height: "60px"}} className="mx-0 w-100 ">
                  <Col xs={3} className={"mx-1 px-0 align-self-center " + classes.ColFontSize}><img className={classes.CoinLogo} src={coin.logo != null && coin.logo != "" ? coin.logo : noLogo } alt="Logo" /></Col>
                  <Col className={"px-0 align-self-center " + classes.ColFontSize + " " + classes.FullName}>{coin.name} ({coin.symbol})</Col>
                  <Col className={"px-0 align-self-center " + classes.ColFontSize + " " + classes.SmallName}>{coin.symbol}</Col>
              </Row>
            </Col>
            <Col xs={3} className={"px-0 align-self-center " + classes.ColFontSize}>
                {Utils.formatPrice(coin.marketCap)}
            </Col>
            <Col sm={3} xs={2} className={"px-0 align-self-center " + classes.ColFontSize + " " + classes.FullName}>
              <p>{Utils.timeSinceLaunch(coin.launchDate)}</p>
            </Col>
            <Col sm={3} xs={2} className={"px-0 align-self-center " + classes.ColFontSize + " " + classes.SmallName}>
              <p>{Utils.timeSinceLaunchSmall(coin.launchDate)}</p>
            </Col>

            <Col sm={2} xs={3} className={"px-0 align-self-center " + classes.ColFontSize}>
          
            {(coin.isPublished==1?
            <Badge pill variant="success">
            Online
            </Badge>:
            <Badge pill variant="dark">
            Under Review
            </Badge>)}
            </Col>

          </Row>
        </a>
      </div>
      
    );
  }

  renderPromoted4thPlace(index) {
    if(this.state.coin4thplace && index == 3 && this.state.searchResults.length == 0){
      return(<div><Badge pill variant="info">Promoted</Badge> <br></br></div>)
    }
  }

renderCoinRow(coin, index) {
  return(
    <div key={coin.id} className={classes.RowContain}>
        <Row style={{height: "60px"}} className="mx-0 w-100">
          <Col sm={10} xs={9} className="px-0">
            <a href={'/coin/'+coin.id} className={classes.RowLink}>
              <Row style={{height: "60px"}} className="mx-0 w-100">
                <Col className={"px-0 align-self-center " + classes.ColFontSize}>
                  <Row style={{height: "60px"}} className="mx-0 w-100">
                    <Col xs={3} className={"mx-1 px-0 align-self-center " + classes.ColFontSize}><img className={classes.CoinLogo} src={coin.logo != null && coin.logo != "" ? coin.logo : noLogo } alt="Logo" /></Col>
                    <Col className={"px-0 align-self-center " + classes.ColFontSize + " " + classes.FullName}>{this.renderPromoted4thPlace(index)} {coin.name} ({coin.symbol})</Col>
                    <Col className={"px-0 align-self-center " + classes.ColFontSize + " " + classes.SmallName}>{this.renderPromoted4thPlace(index)} {coin.symbol}</Col>
                  </Row>
                </Col>
                <Col xs={3} className={"px-0 align-self-center " + classes.ColFontSize}>
                  <p>{Utils.formatPrice(coin.marketCap)}</p>
                </Col>
                
                <Col sm={3} xs={2} className={"px-0 align-self-center " + classes.ColFontSize + " " + classes.FullName}>
                  <p>{Utils.timeSinceLaunch(coin.launchDate)}</p>
                </Col>
                <Col sm={3} xs={2} className={"px-0 align-self-center " + classes.ColFontSize + " " + classes.SmallName}>
                  <p>{Utils.timeSinceLaunchSmall(coin.launchDate)}</p>
                </Col>
              </Row>
            </a>
          </Col>
          {
            this.renderVoteButton(coin)
          }

        </Row>
    </div>
  );
}

  updateNumberOfCoinsDisplayed() {
    if(this.state.key == "today") this.setState({coinsDisplayedToday: [...this.state.coinsTodayList].slice(0, this.state.numberOfCoinsDisplayed)});
    else this.setState({coinsDisplayedAllTime: [...this.state.coinsList].slice(0, this.state.numberOfCoinsDisplayed)});
  }

  updateRow(coinUpdated, isUpvote) {
    let coins = [...this.state.allTimeCoinsDisplayed];
    let allCoins = [...this.state.coinsList];
    let promotedCoins = [...this.state.promotedCoinsList];
    let todaysCoins = [...this.state.coinsTodayList];
    let searchResults = [...this.state.searchResults];

    // Update all coin list
    let index = allCoins.findIndex(coin => coin.id == coinUpdated.id);
    if(index != -1) {
      if(isUpvote) {
        allCoins[index].hasVoted = true;
        allCoins[index].votesCount++;
      }
      else {
        allCoins[index].hasVoted = false;
        allCoins[index].votesCount--;
      }
      this.setState({coinsList: allCoins.sort((a,b) => (a.votesCount < b.votesCount) ? 1 : ((b.votesCount < a.votesCount) ? -1 : 0))});
    }
    else {
      // Update all time best list
      index = coins.findIndex(coin => coin.id == coinUpdated.id);
      if(index != -1) {
        if(isUpvote) {
          coins[index].hasVoted = true;
          coins[index].votesCount++;
        }
        else {
          coins[index].hasVoted = false;
          coins[index].votesCount--;
        }
        this.setState({allTimeCoinsDisplayed: coins.sort((a,b) => (a.votesCount < b.votesCount) ? 1 : ((b.votesCount < a.votesCount) ? -1 : 0))});
      }
    }

    //Update search result
    index = searchResults.findIndex(coin => coin.id == coinUpdated.id);
    if(index != -1) {
      if(isUpvote) {
        searchResults[index].hasVoted = true;
        searchResults[index].votesCount++;
      }
      else {
        searchResults[index].hasVoted = false;
        searchResults[index].votesCount--;
      }
      this.setState({searchResultsList: searchResults.sort((a,b) => (a.votesCount < b.votesCount) ? 1 : ((b.votesCount < a.votesCount) ? -1 : 0))});
    }
    
    // Update promoted coins list
    index = promotedCoins.findIndex(coin => coin.id == coinUpdated.id);
    if(index != -1) {
      if(isUpvote) {
        promotedCoins[index].hasVoted = true;
        promotedCoins[index].votesCount++;
      }
      else {
        promotedCoins[index].hasVoted = false;
        promotedCoins[index].votesCount--;
      }
      this.setState({promotedCoinsList: promotedCoins.sort((a,b) => (a.votesCount < b.votesCount) ? 1 : ((b.votesCount < a.votesCount) ? -1 : 0))});
    }
    
    // Update today's best list
    index = todaysCoins.findIndex(coin => coin.id == coinUpdated.id);
    if(index != -1) {
      if(isUpvote) {
        todaysCoins[index].hasVoted = true;
        todaysCoins[index].votesCount++;
      }
      else {
        todaysCoins[index].hasVoted = false;
        todaysCoins[index].votesCount--;
      }
      if(this.state.coin4thplace && todaysCoins[index].id == this.state.coin4thplace.id){
        this.setState({coinsTodayList: todaysCoins});
      }else{
        if(this.state.coin4thplace) todaysCoins.splice(3, 1);
        this.setState({coinsTodayList: todaysCoins.sort((a,b) => (a.votesCount < b.votesCount) ? 1 : ((b.votesCount < a.votesCount) ? -1 : 0))});
      }
    }
  }

  renderTableHeaders() {
    return(
      <Row style={{height: "40px"}} className="mx-0 w-100">
        <Col sm={10} xs={9} className="px-0">
          <Row style={{height: "40px"}} className="mx-0 w-100">
            <Col className={"px-0 align-self-center " + classes.ColFontSize}>
              {this.searchBar()}
            </Col>
            <Col xs={3} className={"px-0 align-self-center " + classes.ColFontSize}>
              <div className={classes.TopColumn}>Market cap</div>
            </Col>
            <Col sm={3} xs={2} className={"px-0 align-self-center " + classes.ColFontSize + " " + classes.FullName}>
              <div className={classes.TopColumn}>Time since launch</div>
            </Col>
            <Col sm={3} xs={2} className={"px-0 align-self-center " + classes.ColFontSize + " " + classes.SmallName}>
              <div className={classes.TopColumn}>Since launch</div>
            </Col>
          </Row>
        </Col>
        <Col sm={2} xs={3} className={"px-0 align-self-center " + classes.ColFontSize}>
          <div className={classes.TopColumn}>Votes</div>
        </Col>
      </Row>
    )
  }

  handleClose = () => {
    this.setState({show:false});
  }

  handleShow = () => {
    this.setState({show:true});
  }

  handleCloseVoteBottingModal = () => {
    this.setState({showVoteBottingModal:false});
  }

  handleShowVoteBottingModal = () => {
    this.setState({showVoteBottingModal:true});
  }

  renderShowAllForToday() {
    if(this.state.loadingShowAllToday) {
      return(
        <div className={classes.RowShowAllLoader}>
          <Spinner animation="border" variant="success" />
        </div>
      )
    }
    else if(this.state.coinsTodayList && (this.state.coinsTodayList.length == 10 || this.state.coinsTodayList.length == 11) && !this.state.allTodaysCoinsDisplayed && this.state.searchResults.length == 0 && !this.state.noSearchResults) {
      return(
        <div className={classes.RowShowAll} onClick={() => {this.getAllCoinsForToday()}}>
          <Icon.ChevronDown style={{marginRight: "5px"}} />
          <p className={classes.ShowAll}>Show all</p>
        </div>
      )
    }
  }

  renderShowMore() {
    if(this.state.loadingShowMore) {
      return(
        <div className={classes.RowShowAllLoader}>
          <Spinner animation="border" variant="success" />
        </div>
      )
    }
    else if(this.state.searchResults.length == 0 && !this.state.noSearchResults) {
      return(
        <div className={classes.RowShowAll} onClick={() => {this.showMore()}}>
          <Icon.ChevronDown style={{marginRight: "5px"}} />
          <p className={classes.ShowAll}>Show more</p>
        </div>
      )
    }
  }

  showMore() {
    let numberDisplayed = this.state.allTimeCoinsDisplayed.length;
    if(this.state.isAllLoaded) {
      this.setState({allTimeCoinsDisplayed: [...this.state.coinsList].slice(0, numberDisplayed + 10)});
    }
    else {
      this.setState({loadingShowMore: true});
      this.coinServices.getAllCoinsApproved(false, numberDisplayed, 10).then(res => {
        let newList = [...this.state.allTimeCoinsDisplayed];
        newList = newList.concat(res);
        this.setState({allTimeCoinsDisplayed: newList});
        this.setState({loadingShowMore: false});
      });
    }
  }

  getAllCoinsForToday() {
    this.setState({loadingShowAllToday: true, allTodaysCoinsDisplayed: true});
    this.coinServices.getTodaysCoinsApproved(true).then(res => {
      this.setState({coinsTodayList: res});
      this.setState({loadingShowAllToday: false});
    });
  }

  renderAllTimeTable() {
    if(this.state.loadingAllTime) {
      return(
        <Spinner style={{marginTop: "50px", marginBottom: "50px"}} animation="border" variant="success" />
      )
    }
    else {
      return(
        <div>
          {this.renderTableHeaders()}

          {this.state.searchResults.length > 0 || this.state.noSearchResults ?
          (this.state.searchResults.map(coin => (
            this.renderCoinRow(coin)
          )))
          :
          (this.state.allTimeCoinsDisplayed.map(coin => (
            this.renderCoinRow(coin)
          )))}

          {this.renderShowMore()}
        </div>
      )
    }
  }


  renderTodaysBest(){
    var array = this.state.coinsTodayList;
    var ajoute = this.state.coin4thplace;
    if(array && ajoute && !array.includes(ajoute)){
      array.insert(3, ajoute);
    }
    
    return(
    <div>
      {this.renderTableHeaders()}

      {this.state.searchResults.length > 0 || this.state.noSearchResults ?
      (this.state.searchResults.map((coin, index) => (
        this.renderCoinRow(coin, index)
      )))
      :
      (this.state.coinsTodayList.map((coin, index) => (
        this.renderCoinRow(coin, index)
      )))}

      {this.renderShowAllForToday()}
    </div>
    )
  }

  onCaptchaValided = (token, ekey) => {
    this.voteFunction(null, this.state.votingCoin, false, token);
    this.handleCloseCaptcha();
  }

  renderContent() {
    if(this.state.loadingAll) {
      return(
        <Spinner style={{marginTop: "35vh"}} animation="border" variant="success" />
      )
    }
    else {
      return(
        <div className={classes.contentwrapper}>

        <div className={classes.MainContainer}>
        <Modal style={{width: "auto", left: "calc(50% - 165px)", top: "30%"}} show={this.state.showCaptcha} onHide={this.handleCloseCaptcha}>
          <div style={{padding: "8px 6px 0 8px"}}>
            <HCaptcha
              sitekey={Utils.captchaApiKey}
              onVerify={(token,ekey) => this.onCaptchaValided(token, ekey)}
            />
          </div>
        </Modal>
        
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Too many votes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <React.Fragment>
            <p>Retry in one minute</p>
          </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showVoteBottingModal} onHide={this.handleCloseVoteBottingModal}>
          <Modal.Header closeButton>
            <Modal.Title>Suspected vote botting</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <React.Fragment>
            <p>Votes for this coin are disabled for the moment, they will be reactivated soon.</p>
          </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseVoteBottingModal}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

      {this.renderPromotedDiv()}
      
      <Tabs className={classes.MenuTabs} defaultActiveKey="today" activeKey={this.state.key} onSelect={(k) => {
        this.setState({key:k});
        this.updateNumberOfCoinsDisplayed();
        this.setState({isSearching: false, searchResults: [], noSearchResults: false});
        if(k == "alltime") {
          if(this.state.allTimeCoinsDisplayed.length == 0) {
            this.setState({loadingAllTime: true});
            this.coinServices.getAllCoinsApproved(false).then(res => {
              this.setState({allTimeCoinsDisplayed: res});
              this.setState({loadingAllTime: false});
            });
          }
          else {
            this.setState({allTimeCoinsDisplayed: [...this.state.allTimeCoinsDisplayed].slice(0, 10)});
          }
        }
      }}>
         
        <Tab eventKey="alltime" title="All time best">
          <div className={classes.table}>
              {this.renderAllTimeTable()}
          </div>
        </Tab>
        <Tab eventKey="today" title="Today's best" default>
          <div className={classes.table}>
              {this.renderTodaysBest()}
          </div>
        </Tab>

        {this.state.loggedin ?         
        <Tab eventKey="yourhunts" title="Your Hunts">
          <div>
        {this.renderUserDiv()}</div>
        </Tab> : ""}
        
       </Tabs>

       </div>

      </div>
      )
    }
  }

}
export default Landing;