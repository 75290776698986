import React, { useState } from "react"

import CoinServices from '../Services/CoinServices.js'

import { Component } from "react";
import { Col, Row } from 'react-bootstrap';
import classes from "./CommentForm.module.css";

class CommentForm extends Component{
 state={
  reactionState:[
    ['rocket', "🚀"],
    ['heart', "❤️"],
    ['up', "👍"],
    ['down', "👎"],
    ['anger', "😡"],
    ['danger', "⚠️"],
   ],
  reactTempo:[],
  userVoted:[]
 }
 
  coinServices = new CoinServices();

  componentDidMount() {

    this.setState({reactTempo:  this.props.reacts})
   
  }
  render(){
    return(
        <div>
          <Row className={"mx-0 w-100 " + classes.rowContainer}>
            <div className={classes.emojiRow}>
              {this.renderPollColumns(this.props.reacts)}
            </div>
          </Row>
          {this.renderLoginDiv()}
        </div>
    )
  }

  renderLoginDiv() {
    if(this.props.delayedLoggedin == false) {
      return(
        <div style={{marginTop: "5px", cursor: "pointer"}} onClick={() => window.location='/login'}>Please log in to react</div>
      )
    }
  }
  

  renderPollColumns(e){
    let reactions = this.state.reactionState;

    var reactArray = [];

    var reactButtons = [];

    reactions.map((react,key) => {

    reactArray[key] = [];
    reactButtons[key] = [];

    var param = key;
    this.state.reactTempo.map((obj,key)=>{
    if(react[0]===obj.content){
      reactArray[param].push(obj);
    }
    })
    });
    
    var render = [];
    reactArray.map((obj,a)=>{
      obj.map((emoji,b)=>{
      if(emoji.avatar == this.props.user.uid){
      if (this.state.userVoted.indexOf(a) === -1) this.state.userVoted.push(a);
      }
    })
    //à verifier également au back qu'un utilisateur n'a envoyé le meme commentaire une seul fois par coin
    let height = (obj.length/this.state.reactTempo.length)*100;

    if(!this.state.userVoted.includes(a)){
      render.push( 
        <Col key={a} className={"px-0 " + classes.reactionContainer}>
          {this.renderReactNumber(obj, height)}
          <div style={{height:(height ? height + 2 : 0), bottom:(height ? -(124 - height) : 0), backgroundColor:"#d5bf86"}} className={classes.voteBar}>
          </div>
          <div>
          <div className={this.props.loggedin ? classes.emojiContainer : classes.emojiContainerNotLoggedIn} onClick={()=>this.react(a)}>
          <div className={classes.emojiImage}> {this.state.reactionState[a][1]}</div> 
          </div>
          </div>
        </Col>) 
    }else{
      render.push( 
        <Col key={a} className={"px-0 " + classes.reactionContainer}>
          {this.renderReactNumber(obj, height)}
          <div style={{height:(height ? height : 0), bottom:(height ? -(124 - height) : 0), backgroundColor:"#BFD7EA"}} className={classes.voteBar}>
          </div>
          <div>
          <div className={classes.emojiContainerVoted}>
          <div className={classes.emojiImage}> {this.state.reactionState[a][1]}</div> 
          </div>
          </div>
        </Col>) 
    }
  

    }
    );
    
    return (render);
   }

  renderReactNumber(obj, height) {
    if(obj.length != 0) {
      return(
        <div className={classes.reactNum} style={{position: "relative", bottom:(height ? -(124 - height) : -124)}}>{obj.length}</div>
      )
    }
  }

  react(id){
    if(this.props.loggedin) {
      this.setState({testnumber: this.state.testnumber})
      
      var comment = { name: this.props.user.displayName, avatar: this.props.user.uid, content: this.state.reactionState[id][0], email: this.props.user.email };
    
      var reactArray = this.state.reactTempo;
      reactArray.push(comment);

      this.coinServices.addComment(this.props.coin.id, this.props.user.displayName, this.props.user.uid,this.state.reactionState[id][0], this.props.user.email).then(res => {
        return;
      })
    }
  }

}


export default CommentForm