import React,{Component} from 'react';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import classes from './CoinForm.module.css';
import { Form, Button, Modal, Row, Col, InputGroup, Card } from 'react-bootstrap';
import CoinServices from '../Services/CoinServices';
import Utils from '../Utils.js'
import * as Icon from 'react-bootstrap-icons';
import bsc from '../Resources/Images/bsc.png';
import eth from '../Resources/Images/eth.png';
import solana from '../Resources/Images/solana.png';
import website from '../Resources/Images/website.png';
import telegram from '../Resources/Images/telegram.png';
import twitter from '../Resources/Images/twitter.png';

const uiConfig = {
    callbacks: { 
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        var user = authResult.user;
        var credential = authResult.credential;
        var isNewUser = authResult.additionalUserInfo.isNewUser;
        var providerId = authResult.additionalUserInfo.providerId;
        var operationType = authResult.operationType;
        // Do something with the returned AuthResult.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return true;
      },
      signInFailure: function(error) {
        // Some unrecoverable error occurred during sign-in.
        // Return a promise when error handling is completed and FirebaseUI
        // will reset, clearing any UI. This commonly occurs for error code
        // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
        // occurs. Check below for more details on this.
        // return handleUIError(error);
      },
  
    },
    queryParameterForSignInSuccessUrl: 'signInSuccessUrl',
    signInFlow:'popup',
    signInSuccessUrl: '',//Specifying sign in success url can cause double redirect since we are also managing redirect in react-router with local state.
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    // Other config options...
} 
class CoinForm extends Component{

  coinServices = new CoinServices();

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  state={
    user:{},
    showDoubleModal: false,
    showSubmissionModal: false,
    doubleId: "",
    submitted: false,
    tooManyHunts: false
  }


  componentDidMount(){
      

    var unsubscribe = firebase.auth().onAuthStateChanged((user) => {

      if (user) {
      
        if(user.providerData[0]){
        this.setState({user: user});
        this.setState({loggedin:true});
        unsubscribe();
        }
      } else {
        // No user is signed in.
        this.setState({loggedin:false});
      }
     });
     
      

  }



 
render() {
  return(
    <div>
      <div className={classes.contentwrapper}>

      <Modal show={this.state.showDoubleModal} onHide={this.handleCloseDoubleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Coin already existing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <React.Fragment>
          <p>Your coin is already listed on coinhunt or is under review, if you have any modification request please contact us at contact@coinhunt.cc</p>
          <a href={'https://coinhunt.cc/coin/' + this.state.doubleId}>https://coinhunt.cc/coin/{this.state.doubleId}</a>
        </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseDoubleModal}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.showSubmissionModal} onHide={this.handleCloseSubmissionModal}>
        <Modal.Header closeButton>
          <Modal.Title>Thank you for your submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <React.Fragment>
          <p>Your request is now under review</p>
        </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseSubmissionModal}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.showTooManyHunts} onHide={this.handleCloseTooManyHunts}>
        <Modal.Header closeButton>
          <Modal.Title>Too many hunts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <React.Fragment>
          <p>You have added the maximum number of coins per account</p>
        </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseTooManyHunts}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
        
      {this.props.loading?<p>Loading..</p>:
      (!this.props.loggedin?
        
      <React.Fragment>
          <p>Please sign in to see this page.</p>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} className={classes.emailbox}/>
      </React.Fragment>:
        <div className={classes.Container}>
          <div className={classes.BackButton} onClick={() => window.location='/'}>
            <Icon.ArrowLeftShort style={{width: "30px", height: "30px", paddingBottom: "3px"}} />
            <span>Back to home</span>
          </div>
          <div style={{alignSelf: "left", textAlign:"left", marginBottom: "8px"}}>
            <div className={classes.TopTitle}>Coin listing request</div>
          </div>
          <div className="card" style={{padding: "20px"}}>
            <Form onSubmit={this.handleSubmit}>
              <Row style={{textAlign:"left"}}>
                <Col sm={12} md={6}>
                  <h4>Coin informations</h4>
                  <Form.Group controlId="formName">
                    <label className={classes.FormLabel}>Name</label>
                    <span className="text-danger ml-2" style={{fontSize: "0.6rem"}}>Required</span>
                    <Form.Control placeholder="Ex: Bitcoin" required maxLength="50" />
                  </Form.Group>
                  <Form.Group controlId="formSymbol">
                    <label className={classes.FormLabel}>Symbol</label>
                    <span className="text-danger ml-2" style={{fontSize: "0.6rem"}}>Required</span>
                    <Form.Control placeholder="Ex: BTC" required maxLength="50" />
                  </Form.Group>
                  <Form.Group controlId="formDescription">
                    <label className={classes.FormLabel}>Description</label>
                    <Form.Control placeholder="Ex: Bitcoin is a decentralized digital currency" as="textarea" rows={3} maxLength="1000" />
                  </Form.Group>
                  <Form.Group controlId="formLogo">
                    <label className={classes.FormLabel}>Logo</label>
                    <Form.Control placeholder="Ex: https://i.ibb.co/logo.png" maxLength="300" />
                  </Form.Group>
                  <Form.Group controlId="formPrice">
                    <label className={classes.FormLabel}>Price</label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{backgroundColor: "unset", borderRight: 0}}>
                          <p style={{color: "gray", fontSize: "0.9rem"}}>$</p>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control style={{borderLeft: 0}} placeholder="Ex: 0.006" type="number" min="0" step=".00000000000000001"/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="formMarketCap">
                    <label className={classes.FormLabel}>Market cap</label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{backgroundColor: "unset", borderRight: 0}}>
                          <p style={{color: "gray", fontSize: "0.9rem"}}>$</p>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control style={{borderLeft: 0}} placeholder="Ex: 150000" type="number" min="0"/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="formLaunch">
                    <label className={classes.FormLabel}>Launch date</label>
                    <span className="text-danger ml-2" style={{fontSize: "0.6rem"}}>Required</span>
                    <Form.Control type="datetime-local" required />
                  </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                  <h4>Coin contracts</h4>
                  <Form.Group controlId="formBscContract">
                    <label className={classes.FormLabel}>Binance Smart Chain</label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{backgroundColor: "unset", borderRight: 0}}>
                          <img src={bsc} style={{ width: "15px", height: "15px" }} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control style={{borderLeft: 0}} placeholder="Ex: 0x0000000000000000000" maxLength="100" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="formEthContract">
                    <label className={classes.FormLabel}>Ethereum</label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{backgroundColor: "unset", borderRight: 0}}>
                          <img src={eth} style={{ width: "15px", height: "15px" }} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control style={{borderLeft: 0}} placeholder="Ex: 0x0000000000000000000" maxLength="100" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="formSolContract">
                    <label className={classes.FormLabel}>Solana</label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{backgroundColor: "unset", borderRight: 0}}>
                          <img src={solana} style={{ width: "15px", height: "15px" }} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control style={{borderLeft: 0}} placeholder="Ex: xxxxxxxxxxxxxxxxxxxxxxxxx" maxLength="100" />
                    </InputGroup>
                  </Form.Group>
                  <h4>Coin links</h4>
                  <Form.Group controlId="formWebsite">
                    <label className={classes.FormLabel}>Website</label>
                    <span className="text-danger ml-2" style={{fontSize: "0.6rem"}}>Required</span>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{backgroundColor: "unset", borderRight: 0}}>
                          <img src={website} style={{ width: "15px", height: "15px" }} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control style={{borderLeft: 0}} placeholder="Ex: https://www.bitcoin.com" maxLength="100" required />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="formTelegram">
                    <label className={classes.FormLabel}>Telegram</label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{backgroundColor: "unset", borderRight: 0}}>
                          <img src={telegram} style={{ width: "15px", height: "15px" }} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control style={{borderLeft: 0}} placeholder="Ex: https://t.me/bitcoin" maxLength="100" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="formTwitter">
                    <label className={classes.FormLabel}>Twitter</label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text style={{backgroundColor: "unset", borderRight: 0}}>
                          <img src={twitter} style={{ width: "15px", height: "15px" }} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control style={{borderLeft: 0}} placeholder="Ex: https://twitter.com/CoinhuntCC" maxLength="100" />
                    </InputGroup>
                  </Form.Group>
                  <h4>Additional informations</h4>
                  <Form.Group controlId="formOther">
                    <Form.Control placeholder="Other links, other blockchain contracts, hidden contract or anything else you would like to add to your coin request" as="textarea" rows={3} maxLength="1000" />
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="success" type="submit">
                Submit
              </Button>
              <Card style={{marginTop: "10px", paddingLeft: "5px", paddingRight: "5px"}}>
                <h4>Listing requirements</h4>
                <div style={{textAlign: "left", maxWidth: "510px", alignSelf: "center"}}>
                  <p>🟢 Valid contract address (can be kept hidden for pre launch)</p>
                  <p>🟢 Working website where we can check the contract address</p>
                  <p>🟢 Locked liquidity</p>
                  <p>🟢 Reasonable holding of supply</p>
                </div>
              </Card>
            </Form>
          </div>
        </div>
      )}
      </div>
    </div>

  );
}

handleSubmit(event) {
  this.coinServices.checkExistingCoin(this.getContracts(event)).then(res => {
    if(!res.res) {
      if(!this.state.submitted) {
        this.setState({submitted: true});
        this.coinServices.createCoin(
          event.target.formName.value,
          event.target.formSymbol.value.trim(),
          event.target.formDescription.value,
          event.target.formOther.value,
          event.target.formLaunch.value,
          this.getLinks(event),
          event.target.formLogo.value,
          event.target.formMarketCap.value == "" ? 0 : Number(event.target.formMarketCap.value),
          event.target.formPrice.value == "" ? 0 : Number(event.target.formPrice.value),
          this.getContracts(event),
          this.state.user.email,
          ).then((res) => {
            if(res.data.details == "Too many hunts") {
              this.handleShowTooManyHunts();
            }
            else this.handleShowSubmissionModal();
          }
        )
      }
    }
    else {
      this.handleShowDoubleModal(res.ids[0]);
    }
  })
  event.preventDefault();
  event.persist();
}

getLinks(event) {
  let res = [];
  if(event.target.formTelegram.value != "") {
    let mapTg = new Map();
    mapTg.set("name", "Telegram");
    mapTg.set("value", Utils.formatLinks(event.target.formTelegram.value));
    res.push(Object.fromEntries(mapTg));
  }
  if(event.target.formWebsite.value != "") {
    let mapWs = new Map();
    mapWs.set("name", "Website");
    mapWs.set("value", Utils.formatLinks(event.target.formWebsite.value));
    res.push(Object.fromEntries(mapWs));
  }
  if(event.target.formTwitter.value != "") {
    let mapt = new Map();
    mapt.set("name", "Twitter");
    mapt.set("value", Utils.formatLinks(event.target.formTwitter.value));
    res.push(Object.fromEntries(mapt));
  }
  return res;
}

getContracts(event) {
  let res = [];
  if(event.target.formBscContract.value != "") {
    let mapBsc = new Map();
    mapBsc.set("name", "Binance Smart Chain");
    mapBsc.set("value", Utils.formatContract(event.target.formBscContract.value));
    res.push(Object.fromEntries(mapBsc));
  }
  if(event.target.formEthContract.value != "") {
    let mapEth = new Map();
    mapEth.set("name", "Ethereum");
    mapEth.set("value", Utils.formatContract(event.target.formEthContract.value));
    res.push(Object.fromEntries(mapEth));
  }
  if(event.target.formSolContract.value != "") {
    let mapSol = new Map();
    mapSol.set("name", "Solana");
    mapSol.set("value", Utils.formatContract(event.target.formSolContract.value));
    res.push(Object.fromEntries(mapSol));
  }
  return res;
}

handleCloseDoubleModal = () => {
  this.setState({showDoubleModal:false});
}

handleShowDoubleModal = (id) => {
  this.setState({doubleId: id})
  this.setState({showDoubleModal:true});
}

handleCloseTooManyHunts = () => {
  this.setState({showTooManyHunts:false});
}

handleShowTooManyHunts = () => {
  this.setState({showTooManyHunts:true});
}

handleCloseSubmissionModal = () => {
  this.setState({showSubmissionModal:false});
  window.location='/';
}

handleShowSubmissionModal = () => {
  this.setState({showSubmissionModal:true});
}

}
export default CoinForm;