import React,{Component} from 'react';
import classes from './Dashboard.module.css';
import AdminServices from "../Services/AdminServices";
import { Table } from "react-bootstrap";
import { Modal, Form, Row, Col } from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import {InputCursorText} from "react-bootstrap-icons";
import {forEach} from "react-bootstrap/ElementChildren";
import Utils from '../Utils';
import * as firebase from "firebase";
import moment from "moment"

class Dashboard extends Component{

    state={
        searchedName: "",
        name: 0,
        votesCount: 0,
        isPublished: 0,
        today: 0,
        isReviewed: 0,
        lastSort: "",
        constCoins: [],
        coins: [],
        show: false,
        isAuthorized: false,
        showExportModal: false,
        isReviewMode: true
    }

    adminServices = new AdminServices()

    componentDidMount() {

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                if(Utils.whiteListAdmin.includes(user.uid)) {
                    this.setState({ isAuthorized: true });
                }
                else {
                    window.location.href='/'
                }
            }
            else {
                window.location.href='/'
            }
        })

        this.adminServices.getAllCoins()
            .then(response => {
                this.setState({coins: this.getCoinsForReview(response.res), constCoins: response.res})
            })
            .catch(err => {
                console.log(err.toString())
                if(err.toString().includes("403")){
                    alert("Session timed out")
                    window.location.href='/admin'
                }
            })
    }

    handleClose = () => {
        this.setState({show:false});
    }
    
    handleShow = () => {
        this.setState({show:true});
    }

    handleCloseExportModal = () => {
        this.setState({showExportModal:false});
    }
    
    handleShowExportModal = () => {
        this.setState({showExportModal:true});
    }

    getCoinsForReview(coins) {
        return coins.filter(c => !c.isReviewed).sort((a, b) => Number(a.submissionDate) - Number(b.submissionDate));
    }

    doRotateCoins(){
        this.adminServices.rotateCoins()
            .then(response => {
                window.location.href='/admin/dashboard'
            })
            .catch(err => {
                if(err.toString().includes("403")){
                    alert("Session timed out")
                    window.location.href='/admin'
                }
            })
    }

    searchOnChange = (e) =>{
        let searchedName=e.target.value.trim()
        let filtered = []
        this.state.constCoins.forEach((coin, i) => {
            if(coin.name.toLowerCase().includes(searchedName.toLowerCase())
            || coin.symbol.toLowerCase().includes(searchedName.toLowerCase())){
                filtered.push(coin)
            }
        });
        this.setState({ coins: filtered });
    }


    onSort(e, sortKey, type=0){
        const data = this.state.coins
        //type number
        if(type===1){
            if(this.state[sortKey]!==-1){
                data.sort((a,b) => {
                    if (parseInt(a[sortKey]) < parseInt(b[sortKey]))
                        return 1;
                    if (parseInt(a[sortKey]) === parseInt(b[sortKey]))
                        return 0;
                    if (parseInt(a[sortKey]) > parseInt(b[sortKey]))
                        return -1;
                })
                this.setState({[sortKey]: -1, lastSort: [sortKey][0]})
            }
            else if(this.state[sortKey]===-1){
                data.sort((a,b) => {
                    if (parseInt(a[sortKey]) < parseInt(b[sortKey]))
                        return -1;
                    if (parseInt(a[sortKey]) === parseInt(b[sortKey]))
                        return 0;
                    if (parseInt(a[sortKey]) > parseInt(b[sortKey]))
                        return 1;
                })
                this.setState({[sortKey]: 1, lastSort: [sortKey][0]})
            }
        }
        //type boolean
        else if(type===2){
            if(this.state[sortKey]!==-1){
                data.sort((a,b) => b[sortKey].toString().localeCompare(a[sortKey].toString()))
                this.setState({[sortKey]: -1, lastSort: [sortKey][0]})
            }
            else if(this.state[sortKey]===-1){
                data.sort((a,b) => a[sortKey].toString().localeCompare(b[sortKey].toString()))
                this.setState({[sortKey]: 1, lastSort: [sortKey][0]})
            }
        }
        //type string
        else{
            if(this.state[sortKey]!==1){
                data.sort((a,b) => a[sortKey].toString().localeCompare(b[sortKey].toString()))
                this.setState({[sortKey]: 1, lastSort: [sortKey][0]})
            }
            else if(this.state[sortKey]===1){
                data.sort((a,b) => b[sortKey].toString().localeCompare(a[sortKey].toString()))
                this.setState({[sortKey]: -1, lastSort: [sortKey][0]})
            }
        }
        this.setState({coins: data})
    }

    exportDatabase() {
        this.adminServices.exportDatabase().then(res => {
            const fileData = JSON.stringify(res);
            const blob = new Blob([fileData], {type: "text/plain"});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = 'export' + moment(new Date()).format('DDMMYYYY') + '.json';
            link.href = url;
            link.click();
            this.handleCloseExportModal();
        })
    }

    onReviewSwitchChange() {
        if(!this.state.isReviewMode) {
            this.setState({coins: this.getCoinsForReview(this.state.constCoins)})
        }
        else {
            this.setState({coins: this.state.constCoins})
        }
        this.setState({isReviewMode: !this.state.isReviewMode})
    }

    renderContent() {
        if(this.state.isAuthorized) {
            return(
                <div>
                    <div>
                        <Button onClick={this.handleShow} variant={"danger"} style={{margin: "5px", width: "200px"}}> 
                            Daily shifting script
                        </Button>
                        <Button onClick={() => this.handleShowExportModal()} variant={"primary"} style={{margin: "5px", width: "200px"}}>
                            Export database
                        </Button>
                    </div>

            <input type={"text"} style={{marginTop: "10px"}} placeholder={"🔎 Search"} onChange={this.searchOnChange}/>
            <Row style={{marginLeft: "65px", marginTop: "5px"}}>
                <Col style={{textAlign: "right"}}>
                    <p>Review mode</p>
                </Col>
                <Col style={{textAlign: "left"}}>
                    <Form.Switch type="switch" checked={this.state.isReviewMode} id={"isReviewMode"} onChange={() => this.onReviewSwitchChange()}/>
                </Col>
            </Row>

            <Table style={{width: "100%", tableLayout: "fixed", maxWidth: "1200px", margin: "auto"}}>
                <thead>
                <tr>
                    <th onClick={e => this.onSort(e, 'name')}>
                        Name (Symbol) {this.state.name===0 || this.state.lastSort!=="name" ? "" : this.state.name===1 ? "▼" : "▲"}
                    </th>
                    <th onClick={e => this.onSort(e, 'votesCount',1)}>
                        Votes {this.state.votesCount===0 || this.state.lastSort!=="votesCount" ? "" : this.state.votesCount===1 ? "▲" : "▼"}
                    </th>
                    <th onClick={e => this.onSort(e, 'isPublished',2)}>
                        Is Published {this.state.isPublished===0 || this.state.lastSort!=="isPublished" ? "" : this.state.isPublished===1 ? "▲" : "▼"}
                    </th>
                    <th onClick={e => this.onSort(e, 'today',2)}>
                        Today {this.state.today===0 || this.state.lastSort!=="today" ? "" : this.state.today===1 ? "▲" : "▼"}
                    </th>
                    <th onClick={e => this.onSort(e, 'isReviewed',2)}>
                        Is Reviewed {this.state.isReviewed===0 || this.state.lastSort!=="isReviewed" ? "" : this.state.isReviewed===1 ? "▲" : "▼"}
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.coins.map((item, i) => {
                        let pictoIsPublished = item.isPublished ? classes.pictoGreen : classes.pictoRed
                        let pictoToday = item.today == "true" ? classes.pictoGreen : classes.pictoRed
                        let pictoReviewed = item.isReviewed ? classes.pictoGreen : classes.pictoRed
                    return (
                        <tr key={"coin-"+i} className={classes.clickable} onClick={(e)=>{
                            if(e.ctrlKey) {
                              window.open('/admin/editCoinForm/'+item.id, '_blank');
                            }
                            else {
                                window.open('/admin/editCoinForm/'+item.id);
                            }
                          }}>
                            <td><div className={classes.nameCell}>{item.name} ({item.symbol})</div></td>
                            <td>{item.votesCount}</td>
                            <td><div className={pictoIsPublished}/></td>
                            <td><div className={pictoToday}/></td>
                            <td><div className={pictoReviewed}/></td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
                </div>
            )
        }
    }

    render(){
        return(
        <div className={classes.contentwrapper}>

            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Launching the shifting script</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <React.Fragment>
                    <p>Confirm execution</p>
                </React.Fragment>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => this.doRotateCoins()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={this.state.showExportModal} onHide={this.handleCloseExportModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Export database</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <React.Fragment>
                    <p>Confirm execution, it takes a few seconds</p>
                </React.Fragment>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseExportModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => this.exportDatabase()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            {this.renderContent()}
            
        </div>


        );
    }
}
export default Dashboard;