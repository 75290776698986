import moment from "moment" 
import browserSignature from 'browser-signature';
 
export default class Utils { 

    static id = browserSignature();
 
    static captchaApiKey = "9cd42c1a-f229-4942-896d-ac26a7e73696";
     
    static whiteListAdmin = [ "Nl9xdu0q5jPVadv8IDP67oYqmN63", "DGJF3dZWTrNKSnLRoYVkmkeKw7O2", "7joUwQpJajWPkZxTOi16K5gjf7T2", "Ryqf9C8jBTczUha8GNco5KhVp2N2" ]

    static formatPrice(price) { 
      let formatter = new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: 'USD', 
        maximumSignificantDigits: 10 
      }); 
        
      let formattedPrice = formatter.format(price); 

      if(formattedPrice.split(".")[1] == 0) { 
        formattedPrice = formattedPrice.split(".")[0]; 
      } 

      return formattedPrice;
    } 
 
    static formatDate(date) { 
      let res = ""; 
      if(date) { 
        var options = {year: 'numeric', month: 'long', day: 'numeric' }; 
        res = new Date(date).toLocaleDateString("en-US", options); 
      } 
      return res; 
    } 
 
    static formatDateTime(timestamp) { 
      return moment(new Date(timestamp)).format('YYYY-MM-DDTHH:mm'); 
    } 
 
    static formatLinks(link) { 
      let res = ""; 
      if(!link.startsWith("http")) { 
        res += "https://"; 
      } 
      res += link; 
      return res; 
    } 
 
    static formatContract(contract) { 
      let res = ""; 
      if(contract.startsWith("http")) { 
        const split = contract.split('address/'); 
        if(split.length > 0) { 
          contract = split[1]; 
        } 
      } 
      res += contract; 
      return res; 
    } 
 
    static timeSinceLaunch(date) { 
      let res = "0"; 
      if(date) { 
        res = ""; 
         
        const today = new Date(); 
 
        const diff = today.getTime() - date; 
        const dayNum = Math.trunc(diff / 1000 / 60 / 60 / 24); 
        if(dayNum == 0) { 
          if(diff > 0) { 
            res = "Launched today"; 
          } 
          else { 
            res = "Launching today"; 
          } 
        } 
        else if(dayNum < 0) { 
          res = "Launch in " + Math.abs(dayNum.toString()) + " day" + (dayNum > 1 ? "s" : ""); 
        } 
        else if(dayNum > 30) { 
          const monthNum = Math.trunc(dayNum / 30); 
          res = monthNum.toString() + " month" + (monthNum > 1 ? "s" : ""); 
        } 
        else { 
          res = dayNum.toString() + " day" + (dayNum > 1 ? "s" : ""); 
        } 
      } 
      return res; 
    } 
 
    static timeSinceLaunchSmall(date) { 
      let res = "0"; 
      if(date) { 
        res = ""; 
         
        const today = new Date(); 
 
        const diff = today.getTime() - date; 
        const dayNum = Math.trunc(diff / 1000 / 60 / 60 / 24); 
        if(dayNum == 0) { 
          res = "Today" 
        } 
        else if(dayNum < 0) { 
          res = "In " + Math.abs(dayNum.toString()) + "d"; 
        } 
        else if(dayNum > 30) { 
          const monthNum = Math.trunc(dayNum / 30); 
          res = monthNum.toString() + "m"; 
        } 
        else { 
          res = dayNum.toString() + "d"; 
        } 
      } 
      return res; 
    }
 
}