import React from 'react';
import classes from './Promote.module.css';
import MailchimpSubscribe from "react-mailchimp-subscribe"

import { Form, Button } from 'react-bootstrap';
import promote from '../Resources/Images/promote.svg';
import screenpromote from '../Resources/Images/screenpromote.png';

const url = "https://apieceofart.us1.list-manage.com/subscribe/post?u=f29a0ce9487acf9b1296583e0&id=69ee6fade4";
const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };


const Promote = (props) => {
    return (
        <div className={classes.global}>
            <div className={classes.contentwrapper}>

                <div className={classes.TopRow}>
                    <div className={classes.TopRowLeft}>
                        <img style={{ width: "20%", marginLeft:"39%" }} src={promote} alt="Logo" />
                    </div>
                    
                    <div className={classes.TopRowRight}>
                        <div className={classes.TimeAndName}>
                            <div className={classes.Name}>Promote your coin</div>
                        </div>
                        <div className={classes.CommentContent}>
                            Get the visibility you need.
                            <br>
                            </br>
                            By promoting on coinhunt, your coin will be visible on top of all other coins.
                        </div>
                    </div>
                    <img style={{ width: "60%", marginLeft:"19%" }} src={screenpromote} alt="Logo" />
                </div>
                <div>
                <div className={classes.CommentContent}>
                            To promote your coin
                            <br></br>
                            <Mailto email="contact@coinhunt.cc" subject="Promotion for our coin on Coinhunt" body="Hi, We would like to promote our coin on coinhunt.cc">
                            Mail to: contact@coinhunt.cc
                            </Mailto>
                            <br></br>
                            <h4>Do never pay anyone for a promotion on our platform, unless you have received a confirmation email from this address.</h4>
                            </div> 
                </div>
            </div>

        </div>
    );
}
export default Promote;