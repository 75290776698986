import React,{Component} from 'react';
import firebase from 'firebase';
import classes from './Coin.module.css';

import CoinServices from '../Services/CoinServices.js';

import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';

import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Utils from '../Utils.js'
import copyIcon from '../Resources/Images/copyIcon.png';
import validateIcon from '../Resources/Images/validateIcon.png';
import CommentForm from "./CommentForm"
import noLogo from '../Resources/Images/noLogo.png';
import { Spinner, Row, Col } from 'react-bootstrap';
import telegramWhite from '../Resources/Images/telegramWhite.png';
import reddit from '../Resources/Images/reddit.png';

class Coin extends Component{

    state={
        coin: {},
        comments: {},
        user: {},
        loggedin: false,
        loading: true,
        copyValided: false,
        delayedLoggedin: null
      }
    
    coinServices = new CoinServices();

    componentDidMount() {
      var unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          if(user.providerData[0]){
            this.setState({user: user});
            this.setState({loggedin:true});
          
              unsubscribe();
            }
          } else {
            // No user is signed in.
            this.setState({loggedin:false});
            this.setState({delayedLoggedin:false});
          }
        });
        this.coinServices.getCoinById(this.props.id).then(res => {
          if(!res) {
            window.location='/';
          }
          else {
            this.setState({coin: res})
            this.setState({comments: res.comments});
            this.setState({loading: false});
            var comDiv = document.getElementById("comDiv");
            if(comDiv) comDiv.scrollTop = comDiv.scrollHeight;
          }
        })
    }

    render(){
      return(
        <div className={classes.global}>
          {this.renderContent()}
        </div>

      );
    }

  renderLaunchDate(coin) {
    if(coin.launchDate && coin.launchDate != "") {
      return(
        <Container style={{paddingLeft: 0, textAlign: "left"}}>
          <span className={classes.InfoTitle}>Launch date</span>
          <span className={classes.InfoContent}>{Utils.formatDate(this.state.coin.launchDate)}</span>
        </Container>
      )
    }
  }

  renderLinks(links){
    if(links){
      var arr = links.sort((a, b) => a.name.localeCompare(b.name));
      var divs = [];

      arr.map((link) =>{
        divs.push(
            <Button className={classes.LinksButton} key={link.name} rel="noopener noreferrer" target="_blank" href={link.value} variant="success">
              {this.renderLinkIcon(link.name.toLowerCase())}
              <div className={classes.LinksLabel}>{link.name}</div>
            </Button>
        );
      });
      return divs;
    }
    
  }

  renderLinkIcon(name) {
    switch(name) {
      case "twitter":
        return(
          <Icon.Twitter className={classes.LinksIcon} />
        )
      case "instagram":
        return(
          <Icon.Instagram className={classes.LinksIcon} />
        )
      case "facebook":
        return(
          <Icon.Facebook className={classes.LinksIcon} />
        )
      case "chart":
        return(
          <Icon.GraphUp className={classes.LinksIcon} />
        )
      case "telegram":
        return(
          <img src={telegramWhite} className={classes.LinksIcon} />
        )
      case "website":
        return(
          <Icon.Globe className={classes.LinksIcon} />
        )
      case "reddit":
        return(
          <img src={reddit} className={classes.LinksIcon} />
        )
      case "discord":
        return(
          <Icon.Discord className={classes.LinksIcon} />
        )
      default:
        return(
          <Icon.Heart className={classes.LinksIcon} style={{color: "transparent"}} />
        )
    }
  }

  renderContracts(contracts){

    if(contracts) {

      let arr = contracts.sort((a, b) => a.name.localeCompare(b.name));

      if(arr.length > 0) {
        return(
        <div className={classes.ContractsContainer}>
          { arr.map((contract, i) => {
              return(
                <div key={i} className={classes.AddressContainer}>
                  <span style={{cursor: contract.name == "Binance Smart Chain" || contract.name == "Ethereum" ? 'pointer' : 'auto' }} onClick={() => {this.goToScan(contract.name, contract.value)}} className={classes.AddressTitle}>{contract.name}: </span>
                  <span style={{cursor: contract.name == "Binance Smart Chain" || contract.name == "Ethereum" ? 'pointer' : 'auto' }} onClick={() => {this.goToScan(contract.name, contract.value)}} id={"address" + i} className={classes.Address}>{contract.value}</span>
                  <div className={classes.CopyIcon} onClick={() => {this.copyAddress(i)}}>
                    <img src={this.state.copyValided ? validateIcon : copyIcon} style={{maxWidth: "100%", maxHeight: "100%"}}/>
                  </div>
                </div>
              )
          })}
        </div>)
      }

      else {
        return(
          <div className={classes.AddressContainer + " " + classes.DYOR} onClick={() => {window.open('/DYOR', '_blank');}}>⚠️ The contract is kept hidden for pre launch, make sure to DYOR ⚠️</div>
        )
      }
    }
  }

  goToScan(contractName, contractAddress) {
    if(contractName == "Binance Smart Chain") {
      window.open("https://bscscan.com/address/" + contractAddress, "_blank");
    }
    else if(contractName == "Ethereum") {
      window.open("https://etherscan.io/address/" + contractAddress, "_blank");
    }
  }

  copyAddress(index) {
    var copyText = document.getElementById("address" + index);
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    this.setState({copyValided: true});
    setTimeout(function(){
      this.setState({copyValided: false});
    }.bind(this), 1000);
  }

  displayComment(comment) {
    if(!Utils.nameBanList.includes(comment.name)) {
      return true;
    }
    else if(this.state.user.name == comment.name) {
      return true;
    }
    else {
      return false;
    }
  }

  renderContent() {
    if(this.state.loading) {
      return(
        <Spinner style={{marginTop: "30vh"}} animation="border" variant="success" />
      )
    }
    else {
      return(
        <div>
          <div className={classes.BackButton} onClick={() => window.location='/'}>
            <Icon.ArrowLeftShort style={{width: "30px", height: "30px", paddingBottom: "3px"}} />
            <span>Back to home</span>
          </div>

        <div className={classes.TopRow}>

        <div className={classes.LeftSide}>

          <div className={classes.MainCard}>

            <Row style={{overflow: "hidden", textAlign: "left"}} className="mx-0 w-100">
              <div>
                <img className={classes.LogoSize} src={this.state.coin.logo != null && this.state.coin.logo != "" ? this.state.coin.logo : noLogo } alt="Logo" />
              </div>

              <div className={classes.CoinTitle}>
                <h3>
                {this.state.coin.name} <Badge variant="secondary"> {this.state.coin.symbol} </Badge>
                </h3>

                <div className={classes.ContractTop}>
                  {this.renderContracts(this.state.coin.contracts)}
                </div>

              </div>
            </Row>

            <div className={classes.ContractBot}>
              {this.renderContracts(this.state.coin.contracts)}
            </div>

            <div className={classes.DescDiv}>
              <div className={classes.Description}>
                {this.state.coin.description}
              </div>

              <div style={{height: "200px"}}></div>

              <div className={classes.ReactDiv}>
                <CommentForm reacts={this.state.comments} coin={this.state.coin} user={this.state.user} loggedin={this.state.loggedin} delayedLoggedin={this.state.delayedLoggedin} />
              </div>

            </div>

            <div className={classes.LinksCardMobile}>
          
              {this.renderLinks(this.state.coin.links)}

            </div>

          </div>
          
        </div>

        <div className={classes.RightSide}>

          <div className={classes.DataCard}>
          
            <Container style={{paddingLeft: 0, textAlign: "left"}}>
              <span className={classes.InfoTitle}>Price</span>
              <span className={classes.InfoContent}>{Utils.formatPrice(this.state.coin.price)}</span>
            </Container>
            <Container style={{paddingLeft: 0, textAlign: "left"}}>
              <span className={classes.InfoTitle}>Market Cap</span>
              <span className={classes.InfoContent}>{Utils.formatPrice(this.state.coin.marketCap)}</span>
            </Container>
            
            {this.renderLaunchDate(this.state.coin)}
          
          </div>

          <div className={classes.LinksCard}>
          
            {this.renderLinks(this.state.coin.links)}

          </div>

          <div className={classes.DescCard}>
          
            <div className={classes.Description}>
              {this.state.coin.description}
            </div>

            <CommentForm reacts={this.state.comments} coin={this.state.coin} user={this.state.user} loggedin={this.state.loggedin} />

          </div>
        </div>

      </div>

      </div>
      )
    }
  }

}

export default Coin;