import React, {Component} from "react"
import {Button, Form, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Utils from "../Utils";
import AdminServices from "../Services/AdminServices";



class CommentForm extends Component{

    adminServices = new AdminServices()

    onFormControlChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        let type = e.target.name.split("-")[0]
        let index = e.target.name.split("-")[1]
        if(type==="content"){
            this.props.comments[index].content=e.target.value
        }
        else{
            this.props.comments[index].name=e.target.value
        }
    };

    deleteComment(commentId){
        document.getElementById("comment-"+commentId).remove()
        if(window.confirm("Confirm removal of comment : "+commentId)){
            this.adminServices.deleteComment(this.props.id,commentId)
                .then(response => {
                    if(response.code!==200){
                        alert("ERROR")
                    }
                })
                .catch(err => {
                    if(err.toString().includes("403")){
                        alert("Session timed out")
                        window.location.href='/admin'
                    }
                })
        }
    }

    updateComment(comment){
        if(window.confirm("Confirm update of comment : "+comment.id)){
            this.adminServices.updateComment(this.props.id,comment)
                .then(response => {
                    if(response.code!==200){
                        alert("ERROR")
                    }
                })
                .catch(err => {
                    if(err.toString().includes("403")){
                        alert("Session timed out")
                        window.location.href='/admin'
                    }
                })
        }
    }

    render(){
        return (
            this.props.comments.map((val,i)=>{
                return (
                    <div id={"comment-"+val.id} key={"comment-"+i}>
                        <hr/>
                        <Form.Group>
                            <Row>
                                <Col xs={9}>
                                    <div>ID: {val.id}</div>
                                    <div>Date: {Utils.formatDateTime(val.commentDate).replace('T', ' ')}</div>
                                    <div>Email: {val.email}</div>
                                </Col>
                                <Col xs={3} >
                                    <Button
                                        variant={"danger"}
                                        style={{float: "right"}}
                                        name={"delete"-i}
                                        onClick={() => this.deleteComment(val.id)}
                                    >
                                        X
                                    </Button>
                                    <Button
                                        variant={"primary"}
                                        style={{float: "right", marginRight: "10px"}}
                                        name={"update"-i}
                                        onClick={() => this.updateComment(val)}
                                    >
                                        ✓
                                    </Button>
                                </Col>
                            </Row>


                        </Form.Group>
                        <Form.Group>
                            <Form.Control
                                type={"text"}
                                name={"name-"+i}
                                value={val.name}
                                onChange={this.onFormControlChange}
                                placeholder={"Comment's author's name"}
                            />
                            <Form.Control
                                as="textarea"
                                name={"content-"+i}
                                value={val.content}
                                onChange={this.onFormControlChange}
                                placeholder={"Comment's content"}
                                maxLength="100"
                            />
                        </Form.Group>
                    </div>
                )
            })
        )
    }
}
export default CommentForm;