import React,{Component} from 'react';
import classes from './Home.module.css';
import {Route,Link, Switch} from 'react-router-dom';
import Landing from './Landing';
import Dashboard from './Dashboard';
import Promote from './Promote';
import Admin from './Admin';
import Coin from './Coin';
import EditCoinForm from "./EditCoinForm";
import Login from '../Components/Login';
import firebase from 'firebase';

import Dyor from './Dyor';
import CoinForm from './CoinForm';
import PrivacyPolicy from '../Components/PrivacyPolicy';
import TermsConditions from '../Components/TermsConditions';
import Newsletter from '../Components/Newsletter';
import Disclaimer from '../Components/Disclaimer';
import TwitterIcon from '../Resources/Images/twitterIcon.png';
import TelegramIcon from '../Resources/Images/telegramIcon.png';

import { hotjar } from 'react-hotjar';
import logo from '../Resources/Images/pp2.png';
const firebaseConfig = {
  apiKey: "AIzaSyDoVW8zHZwxHNbeSdX3ZGvs3swsJUqBJ0s",
  authDomain: "coin-b4cef.firebaseapp.com",
  projectId: "coin-b4cef",
  storageBucket: "coin-b4cef.appspot.com",
  messagingSenderId: "479716385191",
  appId: "1:479716385191:web:21551ab13608b6c25fd682",
  measurementId: "G-LFG7HF18PQ"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

hotjar.initialize(2442795, 6);
class Home extends Component{
    
state={
    overlaywidth:0,
    loggedin:null,
    loading:true,
}

openOverlay=()=>{
    this.setState({overlaywidth:100})
}
closeOverlay=()=>{
    this.setState({overlaywidth:0})
}
singOutUser=()=>{
  firebase.auth().signOut().then(()=>{
    // Sign-out successful.
    this.closeOverlay()
  }).catch(function(error) {
    // An error happened.
  })
}
componentWillMount(){
  firebase.auth().onAuthStateChanged((user)  =>{
    if (user) {
      this.setState({loggedin:true,loading:false})
    } else {
      this.setState({loggedin:false,loading:false})  // No user is signed in.
    }
  }); 
}

render(){
return(
<React.Fragment>
<div style={{width:this.state.overlaywidth + '%'}} className={classes.overlay}>
  <span className={classes.closebtn} onClick={this.closeOverlay}>&times;</span>
  <div className={classes.overlaycontent}>
    <ul>
                <li><Link to="/coinForm" onClick={this.closeOverlay}>Add coin</Link></li>
                <li><Link to="/promote" onClick={this.closeOverlay}>Promote</Link></li>
                <li><Link to="/newsletter" onClick={this.closeOverlay}>Newsletter</Link></li>
                <li><Link to="/dyor" onClick={this.closeOverlay}>DYOR</Link></li>
        
                {this.state.loggedin?<li onClick={this.singOutUser}><Link to='/' >Sign Out</Link></li>:<li><Link to="/login" onClick={this.closeOverlay}>Log in</Link></li>}
     </ul>
  </div>
</div>
<div className={classes.Container}>
    <div className={classes.navbarcontainer}>
    <div  className={classes.logocol} ><Link to=""><img style={{ width: "30px",height:"30px"}} src={logo} alt="Logo" />coinhunt</Link></div>
        <div  className={classes.navlist}>
            <ul>
                <li><Link className={classes.linkNavbar} to="/coinForm">Add coin</Link></li>
                <li><Link className={classes.linkNavbar} to="/promote">Promote</Link></li>
                <li><Link className={classes.linkNavbar} to="/newsletter">Newsletter</Link></li>
                <li><Link className={classes.linkNavbar}  to="/dyor">DYOR</Link></li>

                {this.state.loggedin?<li onClick={this.singOutUser}><Link  className={classes.linkNavbar} to='/' >Sign Out</Link></li>:<li><Link className={classes.linkNavbar} to="/login">Log in</Link></li>}
            </ul>
        </div>
        <span className={classes.hamburger} onClick={this.openOverlay}>&#9776;</span>
    </div>
    <div className={classes.contentcontainer}>
    <Switch>
    <Route path='/' exact render={() => <Landing loading={this.state.loading} loggedin={this.state.loggedin} />} />
    <Route exact path="/coin/:id" render={(props)=><Coin id={props.match.params.id}/>} />
    <Route path='/admin/dashboard' exact render={() => <Dashboard/>} />
    <Route path='/admin/editCoinForm/:id' exact render={(props) => <EditCoinForm id={props.match.params.id}/>} />
    <Route path='/coinForm' exact render={() => <CoinForm loading={this.state.loading} loggedin={this.state.loggedin} />} />
    <Route path='/newsletter' exact render={() => <Newsletter/>} />
    <Route path='/privacypolicy' exact render={() => <PrivacyPolicy/>} />
    <Route path='/termsconditions' exact render={() => <TermsConditions/>} />
    <Route path='/admin' exact render={() => <Admin/>} />
    <Route path='/promote' exact render={() => <Promote/>} />
    <Route path='/disclaimer' exact render={() => <Disclaimer/>} />
    <Route path='/dyor' exact render={() => <Dyor/>} />
    <Route path='/login' exact render={() => <Login/>} />
    </Switch>
    </div>

    <div className={classes.footerbasic}>
        <footer>
            <ul className={classes.listinline}>
              <li><Link to="/disclaimer">Disclaimer</Link></li>
              <li><Link to="/privacypolicy">Privacy Policy</Link></li>
              <li><Link to="/termsconditions">Terms & conditions</Link></li>
            </ul>
            <a href="https://twitter.com/coinhuntcc" style={{cursor: "pointer"}} target="_blank"><img style={{height: "30px", width: "30px"}} src={TwitterIcon} alt="Twitter" /></a>
            <a href="https://t.me/CoinhuntCCOfficial" style={{cursor: "pointer", paddingLeft:"5px"}} target="_blank"><img style={{height: "30px", width: "30px"}} src={TelegramIcon} alt="Telegram" /></a>
            
            <p className={classes.copyright}>Coinhunt © 2021 - contact@coinhunt.cc</p>
        </footer>
    </div>
</div>
</React.Fragment>
);
}
}

export default Home;