import React, {Component} from "react"
import {Button, Form, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import classes from "./UI/Emailsubmit/Emailsubmit.module.css";



class LinkForm extends Component{


    onFormControlChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        let type = e.target.name.split("-")[0]
        let index = e.target.name.split("-")[1]
        if(type==="name"){
            this.props.links[index].name=e.target.value
        }
        else{
            this.props.links[index].value=e.target.value
        }
    };

    render(){
        return (
            this.props.links.map((val,i)=>{
                return (
                    <div key={"link-"+i}>
                        <Form.Group>
                            <Row style={{margin:"10px 0"}}>
                                <Col xs={4} style={{padding:"0"}}>
                                    <Form.Control
                                        type={"text"}
                                        name={"name-"+i}
                                        value={this.props.links[i].name}
                                        onChange={this.onFormControlChange}
                                        placeholder={"Link name"}
                                    />
                                </Col>
                                <Col xs={7} style={{padding:"0"}}>
                                    <Form.Control
                                        type={"text"}
                                        name={"value-"+i}
                                        value={this.props.links[i].value}
                                        onChange={this.onFormControlChange}
                                        maxLength="100"
                                        placeholder={"Link value"}
                                    />
                                </Col>
                                <Col xs={1} style={{padding:"0"}}>
                                    <a href={this.props.links[i].value} target="_blank" rel="noopener noreferrer">
                                        <Button variant={"secondary"}>
                                            ➔
                                        </Button>
                                    </a>
                                </Col>
                            </Row>
                        </Form.Group>
                    </div>
                )
            })
        )
    }
}
export default LinkForm;