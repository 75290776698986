import axios from "axios";
import * as firebase from "firebase";
import Utils from "../Utils";

export default class CoinServices {

    baseUrl = "https://api.cnhnt.cc/"

    // Requete pour le all time best
    getAllCoinsApproved(all, from, size) {
        return new Promise((resolve, reject) => {
            axios.get(this.baseUrl + "public/getAllCoinsApproved" + (all ? "" : "?from=" + (from ? from : 0) + "&size=" + (size ? size : 10)))
                .then(response => resolve(response.data.res));
        });
    }

    // Requete pour le today's best
    getTodaysCoinsApproved(all) {
        return new Promise((resolve, reject) => {
            axios.get(this.baseUrl + "public/getTodaysCoinsApproved" + (all ? "" : "?from=0&size=10"))
                .then(response => resolve(response.data.res));
        });
    }

    // Requete pour les promoted coins
    getPromotedCoins() {
        return new Promise((resolve, reject) => {
            axios.get(this.baseUrl + "public/getPromotedCoins")
                .then(response => resolve(response.data.res));
        });
    }

     // Requete pour les 4th promoted coins
     get4thPromotedCoins() {
        return new Promise((resolve, reject) => {
            axios.get(this.baseUrl + "public/getPromoted4thTodayCoins")
                .then(response => resolve(response.data.res));
        });
    }
    
    // Requete pour les coin ajouté par un user
    getAllCoinsByEmail(userEmail) {
        return new Promise((resolve, reject) => {
            firebase.auth().currentUser.getIdToken().then(idToken => {
                axios.get(this.baseUrl + "restricted/getAllCoinsByEmail" + "?email=" + userEmail + "&token=" + idToken)
                    .then(response => resolve(response.data.res));
            })
        });
    }

    // Requete pour la page coin
    getCoinById(coinId) {
        return new Promise((resolve, reject) => {
            axios.get(this.baseUrl + "public/getCoinById/" + coinId)
                .then(response => resolve(response.data.res[0]));
        });
    }

    // Requete qui check si un coin existe déjà pour le formulaire d'ajout
    checkExistingCoin(contracts) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseUrl + "public/checkExistingCoinContracts", contracts)
                .then(response => resolve(response.data));
        });
    }

    // Fonction upvote
    vote(coinData, voteToken) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseUrl + "public/vote/" + coinData.id + "?token=" + voteToken + "&id=" + Utils.id).then(response => resolve(response));
        });
    }
    
    // Fonction down vote
    unVote(coinData) {
        return new Promise((resolve, reject) => {
            axios.post(this.baseUrl + "public/unVote/" + coinData.id + "?id=" + Utils.id).then(response => resolve(response));
        });
    }

    // Requete de création pour le formulaire d'ajout
    createCoin(name, symbol, description, other, launchDate, links, logo, marketCap, price, contracts, hunter) {
        return new Promise((resolve, reject) => {

            const coin = { name: name, symbol: symbol, description: description, other: other, launchDate: new Date(launchDate).getTime(), links: links, logo: logo, 
                marketCap: marketCap, price: price, contracts: contracts, hunter: hunter };

            firebase.auth().currentUser.getIdToken().then(idToken => {
                axios.post(this.baseUrl + "restricted/createCoin" + "?token=" + idToken, coin)
                    .then(response => resolve(response));
            })
        });
    }

    // Requete d'ajout de commentaire
    addComment(coinId, name, avatar, content, email) {
        return new Promise((resolve, reject) => {

            const comment = { name: name, avatar: avatar, content: content, email: email };
            
            firebase.auth().currentUser.getIdToken().then(idToken => {
                axios.post(this.baseUrl + "restricted/addComment/" + coinId + "?token=" + idToken, comment)
                    .then(response => resolve(response));
            })
        });
    }

}