import React, {Component} from 'react';
import classes from './Admin.module.css';
import AdminServices from "../Services/AdminServices";
import {Button, Form} from "react-bootstrap";
import Utils from '../Utils';
import * as firebase from "firebase";

class Admin extends Component{

  state={
    username: "",
    password: "",
    isAuthorized: false
  }

  adminServices = new AdminServices()

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if(Utils.whiteListAdmin.includes(user.uid)) {
          this.setState({ isAuthorized: true });
        }
        else {
          window.location.href='/'
        }
      }
      else {
        window.location.href='/'
      }
    })
  }

  onFormControlChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  signIn = (e) =>  {
    this.adminServices.signIn(this.state.username,this.state.password)
        .then(response => {
          localStorage.setItem('token', response.token)
          window.location.href='/admin/dashboard'
        })
        .catch(err => {
          alert(err)
        })
  }

  render(){
    return(
      <div className={classes.loginwrapper}>
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    if(this.state.isAuthorized) {
      return(
        <div>
          <h1>Please Log In</h1>
          <Form>
            <Form.Group>
              <Form.Control style={{marginBottom: "10px"}} type={"text"} required value={this.state.username} name={"username"} onChange={this.onFormControlChange}  placeholder="Username" />
              <Form.Control type={"password"} required value={this.state.password} name={"password"} onChange={this.onFormControlChange}  placeholder="Password" />
            </Form.Group>

            <Button variant="primary" onClick={this.signIn}>
              Log in
            </Button>
          </Form>
        </div>
      )
    }
  }

}

export default Admin;