import React from 'react';
import classes from './Newsletter.module.css';
import MailchimpSubscribe from "react-mailchimp-subscribe"

import { Form, Button } from 'react-bootstrap';
import bike from '../Resources/Images/bike.svg';
const url = "https://apieceofart.us1.list-manage.com/subscribe/post?u=f29a0ce9487acf9b1296583e0&id=69ee6fade4";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url} />

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value
      });
  
    return (
        <div>
        
            <div   style={{ width:"80%", float:"left",marginTop:"5%"}}>
            <div  style={{ fontSize:"12px", textAlign:"left",paddingBottom:"8px"}}>
                
            {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
            {status === "error" && (
            <div
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: message }}
            />
            )}
            {status === "success" && (
            <div
                style={{ color: "green" }}
                dangerouslySetInnerHTML={{ __html: message }}
            />
            )}
            </div>
            <Form.Group controlId="formBasicEmail">
            <Form.Control  style={{ width:"100%" }}
            ref={node => (email = node)}
            type="email"
            placeholder="Your email" />
            </Form.Group>
            <Button style={{width:"100%"}} variant="primary" onClick={submit}>
                    Submit
            </Button>
            </div>
      </div>
    );
  };
const Newsletter = (props) => {
    return (
        <div className={classes.global}>
            <div className={classes.contentwrapper}>

                <div className={classes.TopRow}>
                    <div className={classes.TopRowLeft}>
                        <img style={{ width: "100%" }} src={bike} alt="Logo" />
                    </div>

                    <div className={classes.TopRowRight}>
                        <div className={classes.TimeAndName}>
                            <div className={classes.Name}>Subscribe to our newsletter</div>
                        </div>
                        <div className={classes.CommentContent}>
                            Get the best high potential coins right into your inbox.
                        </div>
                        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
                    </div>
                </div>
            </div>

        </div>
    );
}
export default Newsletter;